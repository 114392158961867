.cke_format .cke_combo_text {
  width: 300px !important;
}

.cke_format .cke_combo_button {
  border: 1px solid #bcbcbc;
}

div.cke_combopanel.cke_combopanel__strinsert {
  width: 300px !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.fade-exit-active .cellspan {
  max-height: 0px;
  display: block;
  overflow: hidden;
  transition: max-height 300ms ease;
}

.cellspan {
  max-height: 100vh;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav:after,
.nav:before {
  display: table;
  content: ' ';
}

.nav:after {
  clear: both;
}

.nav > li,
.nav > li > a {
  position: relative;
  display: block;
  color: inherit;
}

.nav > li > a {
  padding: 10px 15px;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: #f3f3f3;
}

.nav > li.disabled > a {
  color: #b3b3b3;
}

.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
  color: #b3b3b3;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #f3f3f3;
  border-color: #086e8e;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid transparent;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #086e8e;
  cursor: default;
  background-color: transparent;
  border: 1px solid #086e8e;
  border-bottom-color: transparent;
}
.nav-tabs:navempty {
  background: none;
}

.nav-tabs:not(:empty) {
  background: transparent;
}

.nav-tabs.grey {
  background: #f6f5f5;
}

.nav-tabs > li {
  margin-right: 12px;
  padding-bottom: 2px;
}

.nav-tabs > li.active {
  padding-bottom: 0;
  border-bottom: 2px solid #e0e0e0;
  background: rgba(2, 178, 202, 0.05);
  border-radius: 4px 4px 0 0;
  color: #568797;
}

.nav-tabs {
  /* background: #086e8e; */
  color: #737373;
}

.nav-tabs > li > a {
  color: #fff;
  border: 0;
  margin: 0;
}

.nav-tabs > li > a:hover {
  background-color: transparent;
  border: 0;
}

.nav-tabs > li > a,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  background-color: transparent !important;
  border: 0 !important;
  color: #fff !important;
  font-weight: 500;
}

.nav-tabs > li.disabled > a,
.nav-tabs > li.disabled > a:hover {
  color: hsla(0, 0%, 100%, 0.5);
}

.nav-tabs > li.disabled {
  cursor: not-allowed;
}

.nav-tabs > li:last-child {
  margin-right: 0;
}

.nav-tabs > li > a {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 46px;
  font-weight: 400;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
}

.nav-tabs > li > a,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  color: inherit !important;
  font-weight: 400;
}

.nav-tabs .nav-more {
  flex: 0.5 1;
}

.nav-tabs .nav-more .dropdown-menu {
  top: 50px;
  right: 0;
  left: auto;
  max-height: calc(70vh - 110px);
  overflow: auto;
}

a {
  cursor: pointer;
}

a[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}
